import {useState,useEffect} from "react";
import {Link} from 'react-router-dom';
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import BannerOne from "../components/BannerOne";
import PopularOne from "../components/PopularOne";
import BecomeSellerOne from "../components/BecomeSellerOne";
import FooterOne from "../components/FooterOne";
import ProductsOverview from "../components/ProductsOverview";
import ArchivioInstallazioni from "../components/ArchivioInstallazioni";
import ProductCard from "../components/ProductCard";

const HomePage = () => {
  const [data,setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch('/api/macro_categories')
        .then((response) =>{
          if (!response.ok){
            throw new Error('errore di rete');
          }
          return response.json()
        })
        .then((data) =>{
          setData(data);
          setLoading(false);
        })
        .catch((error) =>{
          setError(error);
          setLoading(false);
        })
    },[]);

    function getCategories() {
      if (!loading && !error){
        // TODO aggiungere map della lista degli oggetti e inserirli
        let cards = data.map(c =>
          {
          return (
                  <Link to={`/prodotti?categoria=${c.id}`}  className="popular-item w-100 h-100">
                    <h6 className="popular-item__title font-15">{c.nome}</h6>
                    <span className="popular-item__qty text-body">{}</span>
                  </Link>
                )}
        );
        return cards;
      }else{
        return []
      }
    }
    function getProductsOverview(){
      if (!loading && !error){
        let cards = data[0].categorie[0].prodotti.map(d =><ProductCard product={d}></ProductCard>);
        return cards;
      }else{
        return [];
      }
    }
  return (
    <section className="change-gradient">
    {/* Preloader */}
    <Preloader />

    {/* HeaderOne */}
    <HeaderOne />

    {/* BannerOne */}
    <BannerOne />

    <ProductsOverview products={getProductsOverview()}/>
    {/* PopularOne */}
    <PopularOne  categories={getCategories()}/>
    
    {/* BecomeSellerOne */}
    <BecomeSellerOne />

    {/* FooterOne */}
    <FooterOne />
  </section>
  );
};

export default HomePage;
