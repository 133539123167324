import FooterOne from "../components/FooterOne"
import HeaderOne from "../components/HeaderOne"
import Preloader from "../helper/Preloader"

const About = () =>{
    return (
        <div className="container">
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2>Chi siamo</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
            <div className="col-sm-10 col-12 text-center">
                
                <p className="fs-4">Offrendo soluzioni personalizzate per le esigenze specifiche dei nostri clienti. Grazie alla nostra vasta gamma di prodotti, che includono illuminazione a LED per lo sport, l'industria, la città e molti altri settori, siamo in grado di soddisfare una vasta gamma di esigenze e garantire un notevole risparmio energetico.</p>
                <p className="fs-4">I nostri prodotti sono realizzati con materiali di alta qualità e sono progettati per garantire una lunga durata e prestazioni ottimali.</p>
            </div>
            <div className="col-sm-10 col-12 text-center">
                <img src={process.env.PUBLIC_URL + "/assets/images/home/90460914_Lediamoluce_Entrata_.jpg"}></img>
            </div>
            <div className="col-sm-10  col-12 text-center">
            <p className="fs-4">Se sei alla ricerca di soluzioni di illuminazione a LED per il risparmio energetico, contattaci oggi stesso per una consulenza personalizzata e un preventivo gratuito. Siamo qui per aiutarti a trovare la soluzione migliore per le tue esigenze di illuminazione a LED.</p>
                <p className="fw-bold fs-3">Lavoriamo su commissione, ci rivolgiamo solo a rivenditori e partite iva.</p>
            </div>  
        </div>
    </div>
    )
}

const AboutPage = ()=>{
    return (
        <section className="change-gradient">
            <Preloader /> 
            <HeaderOne />
            <About />
            <FooterOne />
        </section>
    )
}

export default AboutPage;