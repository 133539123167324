import Card from './Card.jsx'
import patternFive from "../assets/images/shapes/pattern-five.png";
import { Link } from 'react-router-dom';
const ProductsOverview = ({products}) => {
    // TODO aggiungere map della lista degli oggetti e inserirli
    return (
      <section className="blog padding-y-120 section-bg position-relative z-index-1 overflow-hidden">
      <img
          src={patternFive}
          className="position-absolute end-0 top-0 z-index--1"
          alt=""
      />
      <div className='showcase-container'>
      <div className="section-heading style-center mb-64">
        <h5 className="section-heading__title ">I nostri prodotti</h5>
      </div>
          <div className="row gy-4 list-grid-wrapper">
              {products.slice(0,4)}
          </div>
      </div>
      <div className="popular__button text-center">
        <Link
          to="/prodotti"
          className="font-18 fw-600 text-heading hover-text-main text-decoration-underline font-heading"
        >
          Esplora tutti i prodotti
        </Link>
      </div>
      </section>
 );
};

export default ProductsOverview;