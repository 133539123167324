import { Link } from "react-router-dom";
import arrowShape from "../assets/images/shapes/arrow-shape.png"
const BecomeSellerOne = () => {
  return (
    <section className="seller padding-y-120">
      <div className="container container-two">
        <div className="row gy-4">
          <div className="col-lg-12">
            <div className="support position-relative z-index-1">
              <img
                src={arrowShape}
                alt=""
                className="arrow-shape"
              />
              <div className="row align-items-center">
                <div className="col-lg-1 d-lg-block d-none" />
                <div className="col-lg-3 col-md-4 d-md-block d-none">
                  <div className="text-center">
                    <img  className="img-support" src={process.env.PUBLIC_URL + "/assets/images/home/33157231_Lavora_con_noi.jpg"} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 d-lg-block d-none" />
                <div className="col-lg-5 col-md-8">
                  <div className="support-content">
                    <h3 className="support-content__title mb-3">
                      Lavora con noi
                    </h3>
                    <p className="support-content__desc">
                      Siamo alla ricerca di collaboratori e venditori in Italia.
                    </p>
                    <Link
                      to="/contatti"
                      className="btn btn-static-black btn-lg fw-300 pill"
                    >
                      Contattaci subito
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BecomeSellerOne;
