import { Link } from "react-router-dom";
import { useState } from "react";
const NewsCard = ({news_record}) =>{
    return (
      <div className="col-lg-6 col-sm-6">
      <div className="post-item">
        <div className="post-item__thumb">
          <Link to={`/news/${news_record.id}`} className="link">
            <img
              src={process.env.PUBLIC_URL + news_record.foto1}
              className="cover-img"
              alt=""
            />
          </Link>
        </div>
        <div className="post-item__content">
          <div className="post-item__top flx-align">
            <div className="post-item__date font-14 flx-align gap-2 font-14 text-heading fw-500">
              <span className="icon">
                <img
                  src="assets/images/icons/calendar.svg"
                  alt=""
                  className="white-version"
                />
                <img
                  src="assets/images/icons/calendar-white.svg"
                  alt=""
                  className="dark-version"
                />
              </span>
              <span className="text">{news_record.data}</span>
            </div>
          </div>
          <h5 className="post-item__title">
            <Link to={`/news/${news_record.id}`}
    className="link">
              {news_record.titolo}
            </Link>
          </h5>
          <Link
            to={`/news/${news_record.id}`}
            className="btn btn-outline-light pill fw-600"
          >
            Dettagli{" "}
          </Link>
        </div>
      </div>
    </div>)
  }
  
  const News = ({news}) => {
    const [currentPage, setCurrentPage] = useState(1);
    
    const handlePageChange = (pageNumber) => {
        if (pageNumber <= totalPages){
            setCurrentPage(pageNumber);
        }
    };
  
    const newsPerPage = 4;
    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const totalPages = Math.ceil(news.length / newsPerPage);
    const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);
    
    return (
      <section className="padding-y-120  position-relative z-index-1 overflow-hidden ">
        <div className="container container-two ">
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2>Archivio News</h2>
          </div>
        </div>
          <div className="row gy-4 ">
              {currentNews.map((news_record) =>{return <NewsCard news_record={news_record}></NewsCard>})}
          </div>
          {/* Pagination Start */}
          <div className="col-12">
            <nav aria-label="Page navigation">
                    <ul className="pagination common-pagination">
                      {[...Array(totalPages)].map((_, index) => (
                        <li className={currentPage === (index+1) ? "page-item__active" : "page-item"} key={index}>
                          <div id={`page-${index}`} onClick={() => handlePageChange(index + 1)} >
                            {index + 1}
                          </div>
                        </li>
                      ))}
                      { totalPages > 1 && <li className="page-item">
                        <div
                          className= "flx-align gap-2 flex-nowrap page-item"
                          to="#"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                          <span className="icon line-height-1 font-20">
                            <i className="las la-arrow-right" />
                          </span>
                        </div>
                      </li>}
                    </ul>
                  </nav>
            </div>
          {/* Pagination End */}
        </div>
      </section>
    );
  }
  
  export default News;