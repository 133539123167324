import React from "react";
import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";
import FooterOne from "../components/FooterOne";
import { useNavigate } from "react-router-dom";

const NoleggioOperativo = () =>{
    const navigate = useNavigate();
    const goToContactPage = () =>{
        navigate('/contatti')
    }
    return (
        <section className=" position-relative z-index-1 overflow-hidden padding-y-100">
            <div className="section-heading style-center">
                <div className="section-heading__title mb-50">
                    <h2>Noleggio operativo</h2>
                </div>
            </div>
            <div className="row align-items-center justify-content-center mb-50 ">
                <div className="col-10 text-center mb-40 section-bg fs-3">
                    <p>Il noleggio operativo ha una durata minima di 12 mesi e massima di 60 mesi</p>
                    <p>L'importo minimo o massimo noleggiabile dipende dalla tipologia del bene</p>
                    <p>L'ammontare del canone è commisurato al periodo di utilizzo del bene</p>
                    <p>La proprietà del bene è del locatore</p>
                    <p>A fine contratto l'utilizzatore potrà richiedere l'aggiornamento tecnologico, restituire il bene o prorogare il contratto</p>
                </div>
                <div className="col-sm-6 text-center">
                <img src={process.env.PUBLIC_URL + '/assets/images/home/2406237_Banner_NOLEGGIO_OPERATIVO.jpg'} style={{borderRadius:16, marginBottom:50}}></img>
                </div>
            </div>
            <div className="row align-items-baseline justify-content-space-between mb-50">
            <div className="col-sm-6 mb-40 d-flex justify-content-center fs-4">
                <ul className="list-circle">
                <h3>I vantaggi del cliente</h3>
                    <li>Il cliente non immobilizza capitali e non impegna linee di credito</li>
                    <li>Nessun impatto sull'esposizione finanziaria</li>
                    <li>Nessuna gestione fiscale del parco locato</li>
                    <li>Costi certi e programmati con canoni mensili o trimestrali</li>
                    <li>Vantaggi fiscali derivanti dalla deducibilità del canone al 100%</li>
                    <li>Opzione di proroga o rinnovo tecnologico</li>
                </ul>
            </div>
            <div className="col-sm-6 mb-40 d-flex justify-content-center fs-4">
                <ul className="list-circle ">
                <h3>Inclusi nel noleggio</h3>
                    <li>Opere di scavo</li>
                    <li>Impianto elettrico</li>
                    <li>Installazione</li>
                    <li>Pali o torri</li>
                    <li>Per tutta la durata del noleggio con assicurazione ALLRISK</li>
                </ul>
            </div></div>
            <div className="row align-items-center justify-content-center mb-50 ">
                <div className="col-10 text-center mb-40 fs-2">
                    <h3>Noleggio operativo con possibilità di riscatto</h3>
                    <p>Rivolto anche a privati e tutte le categorie</p>
                    <p>Da 12 a 60 mesi!</p>
                </div>
                
                </div>
                <div className="row align-items-center justify-content-center mb-50 ">
                <div className="col-sm-6 text-center mb-40">
                    <button
                        type="button"
                        className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill px-sm-5 fs-2"
                        onClick={goToContactPage}
                    >
                        Contattaci
                    </button> 
                </div>
                </div>
            
        </section>
    );
}

const NoleggioOperativoPage = () =>{
    return (
        <section className="change-gradient">
            <Preloader />
            <HeaderOne />
            <NoleggioOperativo />
            <FooterOne />
        </section>
    )
}
export default NoleggioOperativoPage;