import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Preloader from "../helper/Preloader";
import HeaderOne from "./HeaderOne";
import FooterOne from "./FooterOne";
import Slider from "react-slick";

const ProductDetails = ({ productId }) => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [images, setImages] = useState([]);
    const navigate = useNavigate();
    const goToContactPage = () =>{
        navigate('/contatti',{state:product.nome})
    }
    // Function to fetch product data from the API
    const fetchProduct = async () => {
        try {
            const response = await fetch(`/api/products/${productId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProduct(data);
            console.log(product)
            setImages([data.foto1, data.foto2, data.foto3].filter(i => i !== '/assets/images/products/fnd.jpg'));
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-right m-10" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-left m-10" />
            </button>
        );
    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [],
    };

    useEffect(() => {
        if (productId) {
            fetchProduct();
        }
    }, [productId]);

    if (loading) {
        return <Preloader />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!product) {
        return <div>No product found</div>;
    }

    return (
        <div className="product-details mt-32 padding-b-120">
            <div className="container container-two">
                <div className="row gy-4 product-sidebar section-bg align-items-center">
                    <div className="col-1">
                        <Link to="/prodotti/">
                            <button type="button" className="btn btn-main w-100 pill">
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        </Link>
                    </div>
                    <div className="col-10 text-center">
                        <h4>Scheda prodotto</h4>
                    </div>
                    <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />

                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-product-details"
                            role="tabpanel"
                            aria-labelledby="pills-product-details-tab"
                            tabIndex={0}
                        >
                            {/* Product Details Content Start */}
                            <div className="product-details__thumb text-center fw-bold mb-40">
                                {product.nome}
                            </div>
                            <div className="product-details">
                                <div className="row align-items-center justify-content-center">
                                    {images.length > 1 ? (
                                        <div className="col-8 col-lg-6 text-center">
                                            <Slider {...settings}>
                                                {images.map((img, index) => (
                                                    <img src={process.env.PUBLIC_URL + img} alt={`slide ${index + 1}`} className="img-fluid rounded" />
                                                ))}
                                            </Slider>
                                        </div>
                                    ) : (
                                        <div className="col-10 col-lg-8 text-center">
                                            <img src={process.env.PUBLIC_URL + product.foto1} alt="" className="img-fluid rounded " />
                                        </div>
                                    )}
                                    <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />
                                </div>
                                <div className="product-details__desc text-center">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-4">codice : {product.codice}</div>
                                        <div className="col-4">marca : {product.marca !== null ? product.marca.nome : "non specificata"}</div>
                                    </div>
                                    <div className="mt-40" dangerouslySetInnerHTML={{ __html: product.descrizione_completa }}></div>
                                </div>
                                <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {/* ======================= Product Sidebar Start ========================= */}
                        <div className="product-sidebar row justify-content-center">
                            {product.allegato !== "" ? (
                                <>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill "
                                            onClick={goToContactPage}
                                        >
                                            Contattaci
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill"
                                        >
                                            <a href={process.env.PUBLIC_URL + product.allegato} download={true}>
                                            Scarica la scheda tecnica
                                            </a>
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill px-sm-5"
                                        onClick={goToContactPage}
                                    >
                                        Contattaci
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProductDetailsPage = () =>{
    const {id} = useParams();
    return (
            <>
              {/* Preloader */}
              <Preloader />
        
              {/* HeaderOne */}
              <HeaderOne />
        
              {/* AllProduct */}
              <ProductDetails productId={id}></ProductDetails>
                
              {/* FooterOne */}
              <FooterOne />
            </>
          );
}
export default ProductDetailsPage;