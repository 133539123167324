import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";
import AllProductPage from "./pages/AllProductPage";
import ArchivioInstallazioniPage from "./pages/ArchivioInstallazioniPage";
import ProductDetailsPage from "./components/ProductDetails";
import BlogPage from './pages/BlogPage';
import ContattiPage from './pages/ContactPage';
import InformativaPrivacy from './pages/InformativaPrivacy';
import NoleggioOperativo from "./pages/NoleggioOperativoPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ConsulenzaEnergeticaPage from "./pages/ConsulenzaEnergeticaPage";
import AboutPage from "./pages/AboutPage";
import NewsPage from "./pages/NewsPage";
import NewsDetailsPage from './pages/NewsDetailsPage';
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import AuthProvider from "./provider/authProvider";
import ProductManagerPage from "./pages/ProductManagerPage";
import CategoryManagerPage from "./pages/CategoryManagerPage";
import BlogManagerPage from "./pages/BlogManagerPage";
import BrandManagerPage from "./pages/BrandManagerPage";
import NewsManagerPage from "./pages/NewsManagerPage";


function App() {
  const protectedChildren = {path:"/",element:<AdminPage />}
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ScrollToTop smooth color="#A847F0" />
      <AuthProvider>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/prodotti" element={<AllProductPage />} />
        <Route exact path="/installazioni" element={<ArchivioInstallazioniPage />} />
        <Route exact path="/clienti" element={<ArchivioInstallazioniPage />} />
        <Route path="/dettagli_prodotto/:id" element={<ProductDetailsPage />}/>
        <Route exact path="/blog" element={<BlogPage />}/>
        <Route path="/dettagli_blog/:id" element={<BlogDetailsPage />}/>
        <Route exact path="/contatti" element={<ContattiPage />}/>
        <Route exact path="/informativa_privacy" element={<InformativaPrivacy />}/>
        <Route exact path="/noleggio_operativo" element={<NoleggioOperativo />}/>
        <Route exact path="/consulenza_energetica" element={<ConsulenzaEnergeticaPage />}/>
        <Route exact path="/about" element={<AboutPage />}/>
        <Route exact path="/news" element={<NewsPage />}/>
        <Route  path="/news/:id" element={<NewsDetailsPage />}/>
        <Route  exact path="/login" element={<LoginPage />}/>
        <Route exact path="/admin" element={<AdminPage />} />

        <Route path="/admin/prodotto/:id" element={<ProductManagerPage />} />
        <Route exact path="/admin/prodotto" element={<ProductManagerPage />} />
        
        <Route path="/admin/categoria/:id" element={<CategoryManagerPage />} />
        <Route exact path="/admin/categoria" element={<CategoryManagerPage />} />
        
        <Route path="/admin/brand/:id" element={<BrandManagerPage />} />
        <Route exact path="/admin/brand" element={<BrandManagerPage />} />
        
        <Route path="/admin/blog/:id" element={<BlogManagerPage />} />
        <Route exact path="/admin/blog" element={<BlogManagerPage />} />
        
        <Route path="/admin/news/:id" element={<NewsManagerPage />} />
        <Route exact path="/admin/news" element={<NewsManagerPage />} />
        
        {/* <Route exact path="/index-two" element={<HomePageTwo />} />
        <Route exact path="/all-product" element={<AllProductPage />} />
        <Route exact path="/product-details" element={<ProductDetailsPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/cart" element={<CartPage />} />
        <Route exact path="/cart-personal" element={<CartPersonalPage />} />
        <Route exact path="/cart-payment" element={<CartPaymentPage />} />
        <Route exact path="/cart-thank-you" element={<CartThankYouPage />} />
        <Route exact path="/dashboard" element={<DashboardPage />} />
        <Route exact path="/dashboard-profile" element={<DashboardProfilePage />} />
        <Route exact path="/follower" element={<FollowerPage />} />
        <Route exact path="/following" element={<FollowingPage />} />
        <Route exact path="/setting" element={<SettingPage />} />
        <Route exact path="/statement" element={<StatementPage />} />
        <Route exact path="/earning" element={<EarningPage />} />
        <Route exact path="/review" element={<ReviewPage />} />
        <Route exact path="/download" element={<DownloadPage />} />
        <Route exact path="/refund" element={<RefundPage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog-details" element={<BlogDetailsPage />} />
        <Route exact path="/blog-details-sidebar" element={<BlogDetailsSidebarPage />} />
        <Route exact path="/contact" element={<ContactPage />} />

        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/login" element={<LoginPage />} /> */}

      </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
