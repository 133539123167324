import {React, useEffect, useState} from "react";
import { set, useForm } from "react-hook-form";
import { Container, Row, Col, Label } from "reactstrap";
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Preloader from "../helper/Preloader";

const ProductOverview = ({ product, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedProduct = () => {
    onSelect(product);
    navigate(`/admin/product/${product.id}`)
  };

  return (
    
    <div className="row m-2 justify-content-center product-overview" >
      <div className="col-2">
        <button className="button"onClick={setSelectedProduct}>dettagli</button>
      </div>
      <div className="col-2">
        {product.codice}
      </div>
      <div className="col-4">
        {product.nome}
      </div>
      <div className="col-4">
        <img src={process.env.PUBLIC_URL + product.foto1} className="img_overview" alt="product preview" />
      </div>
    </div>
  );
};

const CategoryOverview = ({ category,onSelect }) => {
  const navigate = useNavigate();
  const setSelectedCategory = () => {
    onSelect(category);
    navigate(`/admin/category/${category.id}`)
  };

  return (
    <div className="row m-2 justify-content-center product-overview" >
      <div className="col-2">
        <button className="button"onClick={setSelectedCategory}>dettagli</button>
      </div>
      <div className="col-2">
        {category.nome}
      </div>
      <div className="col-4">
        {category.categorie.length}
      </div>
      <div className="col-4">
        {category.categorie.map((categoria) =>categoria.prodotti.length).reduce((acc,prod_per_cat) =>{return acc +prod_per_cat})}
      </div>
    </div>
  );
};

const BlogOverview = ({ blog,onSelect }) => {
  const navigate = useNavigate();
  const setSelectedBlog = () => {
    onSelect(blog);
    navigate(`/admin/blog/${blog.id}`)
  };

  return (
    <div className="row m-2 justify-content-center product-overview" >
      <div className="col-2">
        <button className="button"onClick={setSelectedBlog}>dettagli</button>
      </div>
      <div className="col-2">
        {blog.titolo}
      </div>
      <div className="col-4">
        {blog.data}
      </div>
      <div className="col-4">
        <img src={process.env.PUBLIC_URL + blog.foto1} className="img_overview"/>
      </div>
    </div>
  );
};

const ObjectOverview = ({ object, name, onSelect }) => {
  console.log(object)
  if (name === 'prodotto') {
    return <ProductOverview product={object} onSelect={onSelect} />;
  } else if (name === 'categoria') {    
    return <CategoryOverview category={object} onSelect={onSelect}/>;
  }else if (name === "blog"){
    return <BlogOverview blog={object} onSelect={onSelect} />
  }
};

const ListElements = ({ objects, name, onSelect ,header,brands}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 8;
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filteredObjects, setFilteredObjects] = useState(objects);
  const [tag,setTag] = useState("")
  
  const location = useLocation();
  const { searchQuery } = location.state || '';
  const handlePageChange = (pageNumber) => {
    if (pageNumber <= totalPages){
      setCurrentPage(pageNumber);
  }
  };

  const handleSelectChange = (event) => {
    setSelectedBrand(event.target.value);
  };
  const handleTagChange = (event) =>{
    setTag(event.target.value)
  }
  useEffect(() => {
    console.log(filteredObjects)
    if (name === "prodotto"){
      let products = objects;

      if (selectedBrand) {
        products = products.filter(product => {if (product.marca !== null){return product.marca.id === selectedBrand}else{return false} });
      }
      if (tag && tag.length > 2){
        console.log(tag)
        products = products.filter(product =>{return product.nome.toLowerCase().includes(tag.toLowerCase())})
      }
      setFilteredObjects(products);
  }
  }, [ selectedBrand, tag]);

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  const currentObjects = filteredObjects.slice(indexOfFirstElement, indexOfLastElement);
  const totalPages = Math.ceil(objects.length / elementsPerPage);

  return (
    <>
      {name === 'prodotto'&& <div className="row">
          <form action="#" className="filter-form pb-4 d-block">
            <div className="row gy-3 flx-between align-items-center">
              <div className="col-5 col-lg-6">
                <div className="flx-between">
                  <label htmlFor="tag" className="form-label font-16">
                    Cerca prodotti
                  </label>
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    className="common-input border-gray-five common-input--withLeftIcon"
                    id="tag"
                    placeholder="Parola chiave..."
                    value={tag}
                    onChange={handleTagChange}
                  />
                  <span className="input-icon input-icon--left">
                    <img src="assets/images/icons/search-two.svg" alt="" />
                  </span>
                </div>
              </div>
              <div className="col-5 col-lg-6">
                <div>
                <label className='form-label font-16' htmlFor="brand-select">Seleziona brand</label>
                </div>
                <select id="brand-select" className='common-input border-gray-five common-input--withLeftIcon position-relative' value={selectedBrand} onChange={handleSelectChange}>
                    <option value="">Tutti</option>
                    {brands.map((brand, index) => (
                      <option key={index} value={brand.id}>
                        {brand.nome}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="line-separator"></div>
            </div>
          </form>
        </div>}
      {header}
      {currentObjects.map((obj) => (
        <ObjectOverview key={obj.id} object={obj} name={name} onSelect={onSelect} />
      ))}
      <div className="col-12">
          <nav aria-label="Page navigation">
              <ul className="pagination common-pagination">
                {[...Array(totalPages)].map((_, index) => (
                  <li className={currentPage === (index+1) ? "page-item__active" : "page-item"} key={index}>
                    <div id={`page-${index}`} onClick={() => handlePageChange(index + 1)} >
                      {index + 1}
                    </div>
                  </li>
                ))}
                {totalPages > 1 && <li className="page-item">
                  <div
                    className= "flx-align gap-2 flex-nowrap"
                    to="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                    <span className="icon line-height-1 font-20">
                      <i className="las la-arrow-right" />
                    </span>
                  </div>
                </li>}
              </ul>
            </nav>
          </div>
    </>
  );
};

const ElementView = ({ object, onSelect }) => {
  const navigate = useNavigate();
  const handleButtonClick = () =>{
    navigate(`/admin/${object.name}`)
  }
  if (object !== null) {
    console.log(object)
    return (
      <div className="container">
        <div className="row">
          <div className="col-4"><button onClick={handleButtonClick}>Nuovo {object.name}</button></div>
        </div>
        <div className="row">
          <ListElements objects={object.objects} name={object.name} onSelect={onSelect} header={object.header} brands={object.brands ? object.brands : null}/>
        </div>
      </div>
    );
  }
  return null;
};
const headers = (name) =>{
  if (name === "prodotto"){
    return <div className="row m-2 font-weight-bold">
            <div className="col-2">Seleziona</div>
            <div className="col-2">Codice</div>
            <div className="col-4">Nome</div>
            <div className="col-4">Anteprima</div>
          </div>
  }else if(name ==="categoria"){
    return <div className="row m-2 font-weight-bold">
    <div className="col-2">Seleziona</div>
    <div className="col-2">Nome</div>
    <div className="col-4">Categorie associate</div>
    <div className="col-4">Numero prodotti associati</div>
  </div>
  }else if( name === "blog"){
    return <div className="row m-2 font-weight-bold">
    <div className="col-2">Seleziona</div>
    <div className="col-2">Titolo</div>
    <div className="col-4">data</div>
    <div className="col-4">anteprima</div>
  </div>
  }
}
const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  
  useEffect(() => {
    async function fetchData() {
      const [prodotti, macro_categories, blog,brands,news] = await Promise.all([
        fetch('/api/products'),
        fetch('/api/macro_categories'),
        fetch('/api/blogs'),
        fetch('/api/brands'),
        fetch('/api/archivio_news'),
      ]);
      const macroCategoriesData = await macro_categories.json();
      const blogData = await blog.json();
      // const installazioniData = await installazioni.json();
      // const clientiData = await clienti.json();
      const prodottiData = await prodotti.json();
      const brandsData = await brands.json();
      const newsData = await news.json();
      return [prodottiData, macroCategoriesData,blogData,brandsData,newsData];
    }
    fetchData()
      .then(([prodotti, categories,blogs,brands,news]) => {
        setElements([
          { name: 'prodotto', objects: prodotti,header:headers('prodotto'),brands:brands},
          { name: 'categoria', objects: categories,header:headers('categoria')},
          { name: 'brand', objects: brands, header:headers("brand")},
          { name: 'blog', objects: blogs, header:headers("blog")},
          { name: 'news', objects: news, header:headers("news")},
        ]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleObjectClick = (name) => {
    setSelectedObject(elements.filter((e) => e.name === name)[0]);
  };

  const handleItemSelect = (object) => {
    setSelectedItem(object);
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
    <div className="row align-items-center">
        <div className="section-heading__title mb-50 col-10" >
          <h2 >Pannello di controllo</h2>
          </div>
          <div className="col-2 ">
              <button >logout</button>
            </div>
        </div>
      </div>
    <div className="row align-items-center justify-content-center mb-50">
      <div className="col-8 col-lg-6 section-bg "></div>
          <div className="navbar row justify-content-center">
            {elements.map((e) => (
              <div className="col-2" key={e.name}>
                <button onClick={() => handleObjectClick(e.name)}>{e.name}</button>
              </div>
            ))}
          </div>
          </div>
      <div className="row align-items-center justify-content-center mb-50">
        <div className="col-8 col-lg-6 section-bg "></div>
          {selectedObject && <ElementView object={selectedObject} onSelect={handleItemSelect}/>}
      </div>
      </section> 
  );
};

export default Admin;

