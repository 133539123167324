import React, { useState, useEffect } from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import AllProduct from "../components/AllProduct";
import ResourceOne from "../components/ResourceOne";
import BrandSectionOne from "../components/BrandSectionOne";
import FooterOne from "../components/FooterOne";
import axios from 'axios';

const AllProductPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function fetchData() {
      const [ macro_categories,brands] = await Promise.all([
        fetch('/api/macro_categories'),
        fetch('/api/brands'),
      ]);
      const macroCategoriesData = await macro_categories.json();
      const brandsData = await brands.json()
      return [ macroCategoriesData,brandsData];
    }
  
    fetchData()
    .then(([categories, brands]) => {
      setData({categories:categories,brands:brands})
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Preloader></Preloader>; // Display loading message while fetching data
  }

  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* HeaderOne */}
      <HeaderOne />

      {/* AllProduct */}
      <AllProduct brands={data.brands} categories={data.categories} />

      {/* BrandSectionOne */}
      <BrandSectionOne />

      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default AllProductPage;
