import { Link } from "react-router-dom";
import pattern from "../assets/images/shapes/pattern.png"
import element1 from "../assets/images/shapes/element1.png"
import element2 from "../assets/images/shapes/element2.png"
import footerGradient from "../assets/images/gradients/footer-gradient.png"
import logo from "../assets/images/icons/lediamoluce.svg"
const FooterOne = () => {
  return (
    <>
      <footer className="footer-section">
        <img
          src={pattern}
          alt=""
          className="bg-pattern"
        />
        <img
          src={element1}
          alt=""
          className="element one"
        />
        <img
          src={element2}
          alt=""
          className="element two"
        />
        <img
          src={footerGradient}
          alt=""
          className="bg--gradient"
        />
        <div className="container container-two">
          <div className="row gy-5">
            <div className="col-sm-3 col-md-">
              <div className="footer-widget">
                <div className="footer-widget__logo">
                  <Link to="/">
                  <img src={logo} alt="Logo" />
                  </Link>
                </div>
                <p className="footer-widget__desc">
                © LEDiamoluce srl
                </p>
                <p className="footer-widget__desc">
                P.IVA 03278950591
                </p>
                <p className="footer-widget__desc">
                Via Carlo Romagnoli 12,Latina
                </p>
                <p className="footer-widget__desc">
                Tel: 07731994982
                </p>
                <p className="footer-widget__desc">
                Fax: 07731994982
                </p>
                <div className="footer-widget__social">
                  <ul className="social-icon-list">
                    <li className="social-icon-list__item">
                      <Link
                        to="https://www.facebook.com/lediamoluce"
                        className="social-icon-list__link flx-center"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li className="social-icon-list__item">
                      <Link
                        to="#"
                        className="social-icon-list__link flx-center"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" />
                      </Link>
                    </li>
                    <li className="social-icon-list__item">
                      <Link
                        to="https://www.instagram.com/lediamoluce/"
                        className="social-icon-list__link flx-center"
                        target="_blank"
                      >
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-6 col-sm-3 ">
              <div className="footer-widget">
                <h5 className="footer-widget__title text-white">Link utili</h5>
                <ul className="footer-lists">
                  <li className="footer-lists__item">
                    <Link to="/prodotti" className="footer-lists__link">
                      Catalogo Prodotti
                    </Link>
                  </li>
                  <li className="footer-lists__item">
                    <Link
                      to="/noleggio_operativo"
                      className="footer-lists__link"
                    >
                      Noleggio operativo
                    </Link>
                  </li>
                  <li className="footer-lists__item">
                    <Link to="blog" className="footer-lists__link">
                      Blog
                    </Link>
                  </li>
                  <li className="footer-lists__item">
                    <Link to="/news" className="footer-lists__link">
                      Archivio news
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-6 col-md-3 col-sm-3  ps-xl-5">
              <div className="footer-widget">
                <h5 className="footer-widget__title text-white">Link rapidi</h5>
                <ul className="footer-lists">
                  <li className="footer-lists__item">
                    <Link to="#" className="footer-lists__link">
                      Archivio installazioni
                    </Link>
                  </li>
                  <li className="footer-lists__item">
                    <Link to="#" className="footer-lists__link">
                      Portfolio clienti{" "}
                    </Link>
                  </li>
                  <li className="footer-lists__item">
                    <Link to="/about" className="footer-lists__link">
                      Chi siamo
                    </Link>
                  </li>
                  <li className="footer-lists__item">
                    <Link to="/contatti" className="footer-lists__link">
                      Contattaci
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-5 col-sm-6 col-12">
              <div className="footer-widget">
                <h5 className="footer-widget__title text-white">Iscriviti</h5>
                <p className="footer-widget__desc">
                  Iscriviti alla nostra newsletter per rimanere aggiornato sulle ultime novità
                </p>
                <form
                  action="#"
                  className="mt-4 subscribe-box d-flex align-items-center flex-column gap-2"
                >
                  <input
                    type="text"
                    className="form-control common-input pill text-white"
                    placeholder="Enter Mail"
                  />
                  <button
                    type="submit"
                    className="btn btn-main btn-lg w-100 pill"
                  >
                    Iscriviti alla newsletter
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* bottom Footer */}
      <div className="bottom-footer">
        <div className="container container-two">
          <div className="bottom-footer__inner flx-between gap-3">
            <p className="bottom-footer__text font-14">
              Copyright © 2024 LEDiamoluce, All rights reserved.
            </p>
            <div className="footer-links">
              <Link to="#" className="footer-link font-14">
                Cookie policy e impostazioni
              </Link>
              <Link to="/informativa_privacy" className="footer-link font-14">
                Informativa Privacy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterOne;
