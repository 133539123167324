import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";
import FooterOne from "../components/FooterOne";
import { useNavigate } from "react-router-dom";
const ConsulenzaEnergetica = () =>{
    const navigate = useNavigate();
    const goToContactPage = () =>{
        navigate('/contatti')
    }
    return (
        <section className=" position-relative z-index-1 overflow-hidden">
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2>Consulenza Energetica</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50">
          <div className="col-6 text-center">
                <h3>Progettazione per illuminazione pubblica e privata: Maggiore potenza e Più economia energetica</h3>
                <p className="fs-5">Volete maggiore potenza e sicurezza? L’uso di Sistemi di Illuminazione realizzati con Tecnologie di nuova generazione a Economia energetica, consente la riduzione dei consumi nel rispetto delle normative e delle leggi in vigore.</p>
                <p className="fs-5">Il Consulente-Energetico progetta e gestisce i lavori di illuminazione pubblica e privata in grado di soddisfare tutte le aspettative di sicurezza, risparmio energetico, efficienza e durata nel tempo.</p>
            </div>
        </div>
        <div className="row align-items-center justify-content-center" style={{marginTop:50,}}>
          <div className="col-6 d-flex justify-content-center">
                <ul className="list-circle">
                <h3 className="text-center">Il nostro intervento di Consulenza e Realizzazione di Progetti è richiesto in opere di:</h3>
                    <li>Aggiornamento Impianti di Illuminazione Pubblica e Illuminazione Privata, Impianti di Illuminazione esterni e interni ottenendo un miglioramento delle prestazioni e interessanti risultati di Efficienza energetica (ai sensi delle Leggi Nazionali e Regionali)</li>
                    <li>Illuminazione Stradale Smart City realizzata con l’obiettivo di ottenere un sistema intelligente di gestione degli impianti con la giusta Potenza di Illuminazione e con la Efficienza energetica</li>
                    <li>Illuminazione esterna per Piazze, parcheggi, Viali.</li>
                    <li>Illuminazione esterna e interna di Capannoni Industriali, Acciaierie, ATEX Zone 1e2</li>
                    <li>Illuminazioni esterna di Impianti Sportivi quali campi da calcetto a 5/7, calcio a 11, Tennis, e altri campi di atletica e di allenamento.</li>
                </ul>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50">
          <div className="col-10 col-lg-8 text-center section-bg " style={{marginTop:50,}}>
                <h3>Richiedi un preventivo gratuito e personalizzato</h3>
                <p className="fs-4" style={{marginBottom:50,}}>Se devi realizzare o adeguare il tuo impianto di Illuminazione Pubblica o Privata, chiedi un consiglio al nostro Consulente Energetico illustrandoci le tue esigenze e inviando i dati tecnici, disegni e documenti utili a sviluppare un preventivo: al più presto ti contatterà per un Preventivo Gratuito e personalizzato per le tue necessità!</p>
                <button
                        type="button"
                        className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill px-sm-5"
                        onClick={goToContactPage}
                    >
                        Contattaci
                    </button>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50" style={{marginTop:50,}}>
          <div className="col-10 col-lg-8 text-center fs-3">
                <div className="fw-bold border rounded">Oggi la sensibilità delle delle persone e delle istituzioni, nel riguardo di tematiche di un’Illuminazione LED a risparmio di energia e di inquinamento luminoso è in aumento, come continua ad aumentare la volontà di ridurre i consumi e di migliorare l’efficienza energetica e di illuminazione degli impianti pubblici e privati.</div>
            </div>
        </div>
        </section>
    )
};

const ConsulenzaEnergeticaPage = () =>{
    return (
        <section className="change-gradient">
        <Preloader />
        <HeaderOne />
        <ConsulenzaEnergetica />
        <FooterOne />
        </section>
    )
}
export default ConsulenzaEnergeticaPage;