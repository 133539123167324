import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';

const FileInput = ({ fieldName, filePath, control }) => {
  const [file, setFile] = useState({ name: filePath, type: filePath.split(".")[1] });

  const handleFileChange = (e, onChange) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      onChange(e.target.files);
    }
  };

  return (
    <div>
      <label htmlFor={fieldName} className="form-label mt-3">{fieldName}</label>
      <Controller
            control={control}
            name={fieldName}
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <input
                  {...field}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                  type="file"
                  id="picture"
                />
              );
            }}
          />
      <div>{file && `${file.name} - ${file.type}`}</div>
    </div>
  );
};

const ProductForm = ({ product, macroCategories, brands }) => {
  const auth = useAuth();
  const { control, handleSubmit, register, getValues, setValue } = useForm({ defaultValues: product });
  const [selectedMacroCategory, setSelectedMacroCategory] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const textFields = [
    { value: product ? product.codice : "", label: 'codice' },
    { value: product ? product.descrizione : "", label: 'descrizione' },
    { value: product ? product.descrizione_completa : "", label: 'descrizione_completa' },
    { value: product ? product.mpn : "", label: 'mpn' },
    { value: product ? product.nome : "", label: 'nome' }
  ];

  const imageFields = [product ? product.foto1 : "", product ? product.foto2 : "", product ? product.foto3 : ""];

  const onSubmit = (data) => {
    console.log(data);
    if (JSON.stringify(data) === JSON.stringify(product)) {
      alert('Form data is equal to default values. Submission prevented.');
      return;
    }
    
    const formData = new FormData();
    if (data.foto1 && data.foto1 instanceof File) {
      formData.append("foto1", data.foto1);
    }
    if (data.foto2 && data.foto2 instanceof File) {
      formData.append("foto2", data.foto2);
    }
    if (data.foto3 && data.foto3 instanceof File) {
      formData.append("foto3", data.foto3);
    }
    if (data.allegato && data.allegato instanceof File) {
      formData.append("allegato", data.allegato);
    }
    
    
    data = {
      ...data,
      foto1: data.foto1 && data.foto1 instanceof File ? `/assets/images/products/${data.foto1.name}` : product.foto1 ? product.foto1 : "/assets/images/products/fnd.jpg",
      foto2: data.foto2 && data.foto2 instanceof File ? `/assets/images/products/${data.foto2.name}` : product.foto2 ? product.foto2 : "/assets/images/products/fnd.jpg",
      foto3: data.foto3 && data.foto3 instanceof File ? `/assets/images/products/${data.foto3.name}` : product.foto3 ? product.foto3 : "/assets/images/products/fnd.jpg",
      allegato: data.allegato && data.allegato instanceof File ? `/assets/images/products/${data.allegato.name}` : product.allegato ? product.allegato : "/assets/images/products/fnd.jpg"
    };
    
    data['marca_id'] = data['marca_id'] == 'non specificato' ? null : parseInt(data['marca_id'],10)
    data['categoria_id'] = data['categoria_id'] == 'non specificato' ? null : parseInt(data['categoria_id'],10)
    delete data['macro_categoria_id']
    delete data['categoria']
    delete data['commenti']
    delete data['marca']
    formData.append("product", JSON.stringify(data));
    
    let url = "/api/admin/products";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/products/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };
  useEffect(() => {
    if (product && product.categoria && product.categoria.macrocategoria) {
      const macroCategory = macroCategories.find(m => m.id === product.categoria.macrocategoria.id);
      setSelectedMacroCategory(macroCategory);
      setFilteredCategories(macroCategory ? macroCategory.categorie : []);
      setValue('macro_categoria_id', product.categoria.macrocategoria.id);
      setValue('categoria_id', product.categoria.id);
    }
  }, [product, macroCategories, setValue]);
  const handleMacroCategoryChange = (macro) => {
    setSelectedMacroCategory(macro);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {textFields.map(field => (
        <div key={field.label}>
          <label htmlFor={field.label} className="form-label mt-3">{field.label}:</label>
          <input type="text" className="form-control" {...register(field.label)} />
        </div>
      ))}

      <div>
        <label htmlFor="macroCategory" className="form-label mt-3">Macro Categoria</label>
        <Controller
          name="macro_categoria_id"
          control={control}
          render={({ field }) => (
            <select
              id="macroCategory"
              className="form-control"
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handleMacroCategoryChange(macroCategories.find(c => c.id == e.target.value));
              }}
            >
              <option value={null}>non specificato</option>
              {macroCategories.map(macro => (
                <option key={macro.id} value={macro.id}>{macro.nome}</option>
              ))}
            </select>
          )}
        />

        {selectedMacroCategory && (
          <>
            <label htmlFor="category" className="form-label mt-3">Categoria</label>
            <Controller
              name="categoria_id"
              control={control}
              render={({ field }) => (
                <select id="category" className="form-control" {...field}>
                  <option value={null}>non specificato</option>
                  {filteredCategories.map(cat => (
                    <option key={cat.id} value={cat.id}>{cat.nome}</option>
                  ))}
                </select>
              )}
            />
          </>
        )}

        {imageFields.map((img, index) => (
          <FileInput key={index} fieldName={`foto${index + 1}`} filePath={img ? img : ""} control={control} />
        ))}

        <FileInput fieldName="allegato" filePath={product ? product.allegato : ""} control={control} />

        <label htmlFor="brand" className="form-label mt-3">Marca</label>
        <select id="brand" className="form-control" {...register("marca_id")}>
          <option value={null}>non specificato</option>
          {brands.map(b => (
            <option key={b.id} value={b.id}>{b.nome}</option>
          ))}
        </select>

        <label htmlFor="in_evidenza" className="form-label mt-3">In evidenza</label>
        <input type="checkbox" {...register("in_evidenza")} />
      </div>

      <button type="submit" className="btn btn-primary mt-3">Save</button>
    </form>
  );
};

const ProductManager = ({ idProduct }) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [macroCategories, setMacroCategories] = useState(Array(0));
  const [brands,setBrands] = useState(Array(0));
  useEffect(() => {
    async function fetchData() {
      let productResponse = null;
      let productData = null;
      if (idProduct !== null){
        productResponse = await fetch(`/api/products/${idProduct}`);
      }
      const macroCategoriesResponse = await fetch('/api/macro_categories');
      const brandsResponse = await fetch('/api/brands');
      if ((productResponse!== null && !productResponse.ok) || !macroCategoriesResponse.ok || !brandsResponse.ok) {
        console.log('error');
        return;
      }
      if (productResponse !== null){
        productData = await productResponse.json();
      }
      const macroCategoriesData = await macroCategoriesResponse.json();
      const brandsData = await brandsResponse.json();
    //   const categoriesData = await categoriesResponse.json();

    //   setProduct(productData);
    //   setMacroCategories(macroCategoriesData);
    //   setCategories(categoriesData);
    //   setLoading(false);
    return [productData,macroCategoriesData,brandsData];
    }

    fetchData().then((data) =>{
        console.log(data[0]);
        console.log(data[1]);
        setMacroCategories(data[1]);
        setProduct(data[0]);
        setBrands(data[2])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idProduct]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading__title mb-50 row justify-content-center" >
        <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
        </div>
          <h2 className='col-10 text-center'>{product !== null ? "modifica prodotto" : "inserisci nuovo prodotto"}</h2>
      <div className="col-8 col-lg-6 section-bg ">
        <ProductForm product={product} macroCategories={macroCategories} brands={brands}/>
       </div>
       </div>
       </section> 
  );
};

export default ProductManager;
